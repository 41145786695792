import { Badge } from "@/shared/components/ui/Badge";

interface BreakpointsBadgeProps {
    className?: string;
}

function BreakpointsBadge(props: BreakpointsBadgeProps) {
    const { className } = props;
    return (
        <div className={className}>
            <Badge variant="secondary" className="2xs:hidden">
                {`BASE: < 360`}
            </Badge>
            <Badge variant="secondary" className="hidden 2xs:inline-flex xs:hidden">
                {`2XS: 360 ≥ 427`}
            </Badge>
            <Badge variant="secondary" className="hidden xs:inline-flex sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">
                {`XS: 428 ≥ 639`}
            </Badge>
            <Badge variant="secondary" className="hidden sm:inline-flex md:hidden lg:hidden xl:hidden 2xl:hidden">
                {`SM: 640 ≥ 767`}
            </Badge>
            <Badge variant="secondary" className="hidden md:inline-flex lg:hidden xl:hidden 2xl:hidden">
                {`MD: 768 ≥ 1023`}
            </Badge>
            <Badge variant="secondary" className="hidden sm:hidden md:hidden lg:inline-flex xl:hidden 2xl:hidden">
                {`LG: 1024 ≥ 1279`}
            </Badge>
            <Badge variant="secondary" className="hidden sm:hidden md:hidden lg:hidden xl:inline-flex 2xl:hidden">
                {`XL: 1280 ≥ 1535`}
            </Badge>
            <Badge variant="secondary" className="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:inline-flex">
                {`2XL: 1536 ≥ Infinity`}
            </Badge>
        </div>
    );
}

export default BreakpointsBadge;
